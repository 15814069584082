import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrResortFragmentDoc } from 'fdr/schemas/fragment/geo/fdr-resort.generated';
import { FdrDestinationFragmentDoc } from 'fdr/schemas/fragment/geo/fdr-destination.generated';
import { FdrRegionFragmentDoc } from 'fdr/schemas/fragment/geo/fdr-region.generated';
import { FdrCountryFragmentDoc } from 'fdr/schemas/fragment/geo/fdr-country.generated';
import { FdrCountryGroupFragmentDoc } from 'fdr/schemas/fragment/geo/fdr-country-group.generated';
import { FdrContinentFragmentDoc } from 'fdr/schemas/fragment/geo/fdr-continent.generated';
import { FdrHotelFragmentDoc } from 'fdr/schemas/fragment/product/fdr-hotel.generated';
import { FdrCruiseFragmentDoc } from 'fdr/schemas/fragment/product/fdr-cruise.generated';
import { FdrAdventureTravelFragmentDoc } from 'fdr/schemas/fragment/product/fdr-adventure-travel.generated';
import { FdrRoundTripFragmentDoc } from 'fdr/schemas/fragment/product/fdr-round-trip.generated';
export type FdrTravelDestinationsFragment = {
  __typename?: 'FdrSearchControlsTravelDestinations';
  destinations?: Array<
    | {
        __typename?: 'FdrSearchControlsAirportDestination';
        airport: {
          __typename?: 'FdrAirport';
          iata: string;
          name: string;
          codes?: Array<string> | null;
          isGroup: boolean;
          id: string;
        };
      }
    | {
        __typename?: 'FdrSearchControlsGeoDestination';
        geo:
          | {
              __typename?: 'FdrDestination';
              name: string;
              id: string;
              region?: {
                __typename?: 'FdrRegion';
                name: string;
                id: string;
                country: {
                  __typename?: 'FdrCountry';
                  name: string;
                  id: string;
                  countryGroup?: {
                    __typename?: 'FdrCountryGroup';
                    name: string;
                    id: string;
                    continent: {
                      __typename?: 'FdrContinent';
                      name: string;
                      id: string;
                    };
                  } | null;
                  continent: {
                    __typename?: 'FdrContinent';
                    name: string;
                    id: string;
                  };
                };
              } | null;
              country: {
                __typename?: 'FdrCountry';
                name: string;
                id: string;
                countryGroup?: {
                  __typename?: 'FdrCountryGroup';
                  name: string;
                  id: string;
                  continent: {
                    __typename?: 'FdrContinent';
                    name: string;
                    id: string;
                  };
                } | null;
                continent: {
                  __typename?: 'FdrContinent';
                  name: string;
                  id: string;
                };
              };
            }
          | {
              __typename?: 'FdrCountry';
              name: string;
              id: string;
              countryGroup?: {
                __typename?: 'FdrCountryGroup';
                name: string;
                id: string;
                continent: {
                  __typename?: 'FdrContinent';
                  name: string;
                  id: string;
                };
              } | null;
              continent: {
                __typename?: 'FdrContinent';
                name: string;
                id: string;
              };
            }
          | { __typename?: 'FdrContinent'; name: string; id: string }
          | {
              __typename?: 'FdrCountryGroup';
              name: string;
              id: string;
              continent: {
                __typename?: 'FdrContinent';
                name: string;
                id: string;
              };
            }
          | {
              __typename?: 'FdrRegion';
              name: string;
              id: string;
              country: {
                __typename?: 'FdrCountry';
                name: string;
                id: string;
                countryGroup?: {
                  __typename?: 'FdrCountryGroup';
                  name: string;
                  id: string;
                  continent: {
                    __typename?: 'FdrContinent';
                    name: string;
                    id: string;
                  };
                } | null;
                continent: {
                  __typename?: 'FdrContinent';
                  name: string;
                  id: string;
                };
              };
            }
          | {
              __typename?: 'FdrResort';
              name: string;
              id: string;
              destination: {
                __typename?: 'FdrDestination';
                name: string;
                id: string;
                region?: {
                  __typename?: 'FdrRegion';
                  name: string;
                  id: string;
                  country: {
                    __typename?: 'FdrCountry';
                    name: string;
                    id: string;
                    countryGroup?: {
                      __typename?: 'FdrCountryGroup';
                      name: string;
                      id: string;
                      continent: {
                        __typename?: 'FdrContinent';
                        name: string;
                        id: string;
                      };
                    } | null;
                    continent: {
                      __typename?: 'FdrContinent';
                      name: string;
                      id: string;
                    };
                  };
                } | null;
                country: {
                  __typename?: 'FdrCountry';
                  name: string;
                  id: string;
                  countryGroup?: {
                    __typename?: 'FdrCountryGroup';
                    name: string;
                    id: string;
                    continent: {
                      __typename?: 'FdrContinent';
                      name: string;
                      id: string;
                    };
                  } | null;
                  continent: {
                    __typename?: 'FdrContinent';
                    name: string;
                    id: string;
                  };
                };
              };
            };
      }
    | {
        __typename?: 'FdrSearchControlsProductDestination';
        product:
          | { __typename?: 'FdrCruise'; name: string; id: string }
          | {
              __typename?: 'FdrHotel';
              name: string;
              mythosCode?: string | null;
              id: string;
              resort: {
                __typename?: 'FdrResort';
                name: string;
                id: string;
                destination: {
                  __typename?: 'FdrDestination';
                  name: string;
                  id: string;
                  region?: {
                    __typename?: 'FdrRegion';
                    name: string;
                    id: string;
                    country: {
                      __typename?: 'FdrCountry';
                      name: string;
                      id: string;
                      countryGroup?: {
                        __typename?: 'FdrCountryGroup';
                        name: string;
                        id: string;
                        continent: {
                          __typename?: 'FdrContinent';
                          name: string;
                          id: string;
                        };
                      } | null;
                      continent: {
                        __typename?: 'FdrContinent';
                        name: string;
                        id: string;
                      };
                    };
                  } | null;
                  country: {
                    __typename?: 'FdrCountry';
                    name: string;
                    id: string;
                    countryGroup?: {
                      __typename?: 'FdrCountryGroup';
                      name: string;
                      id: string;
                      continent: {
                        __typename?: 'FdrContinent';
                        name: string;
                        id: string;
                      };
                    } | null;
                    continent: {
                      __typename?: 'FdrContinent';
                      name: string;
                      id: string;
                    };
                  };
                };
              };
            }
          | { __typename?: 'FdrRoundTrip'; name: string; id: string }
          | { __typename?: 'FdrAdventureTravel'; name: string; id: string };
      }
    | {
        __typename?: 'FdrSearchControlsThemeDestination';
        theme: { __typename?: 'FdrTheme'; name: string; id: string };
      }
  > | null;
  radiusSearch?: {
    __typename?: 'FdrSearchControlsRadiusSearch';
    label: string;
    radius: number;
    center: {
      __typename?: 'FdrCoordinates';
      latitude: number;
      longitude: number;
    };
  } | null;
};

export type FdrSearchControlAirportDestinationsFragment = {
  __typename?: 'FdrSearchControlsAirportDestination';
  airport: {
    __typename?: 'FdrAirport';
    iata: string;
    name: string;
    codes?: Array<string> | null;
    isGroup: boolean;
    id: string;
  };
};

export type FdrSearchControlThemeDestinationsFragment = {
  __typename?: 'FdrSearchControlsThemeDestination';
  theme: { __typename?: 'FdrTheme'; name: string; id: string };
};

export type FdrSearchControlGeoDestinationsFragment = {
  __typename?: 'FdrSearchControlsGeoDestination';
  geo:
    | {
        __typename?: 'FdrDestination';
        name: string;
        id: string;
        region?: {
          __typename?: 'FdrRegion';
          name: string;
          id: string;
          country: {
            __typename?: 'FdrCountry';
            name: string;
            id: string;
            countryGroup?: {
              __typename?: 'FdrCountryGroup';
              name: string;
              id: string;
              continent: {
                __typename?: 'FdrContinent';
                name: string;
                id: string;
              };
            } | null;
            continent: {
              __typename?: 'FdrContinent';
              name: string;
              id: string;
            };
          };
        } | null;
        country: {
          __typename?: 'FdrCountry';
          name: string;
          id: string;
          countryGroup?: {
            __typename?: 'FdrCountryGroup';
            name: string;
            id: string;
            continent: {
              __typename?: 'FdrContinent';
              name: string;
              id: string;
            };
          } | null;
          continent: { __typename?: 'FdrContinent'; name: string; id: string };
        };
      }
    | {
        __typename?: 'FdrCountry';
        name: string;
        id: string;
        countryGroup?: {
          __typename?: 'FdrCountryGroup';
          name: string;
          id: string;
          continent: { __typename?: 'FdrContinent'; name: string; id: string };
        } | null;
        continent: { __typename?: 'FdrContinent'; name: string; id: string };
      }
    | { __typename?: 'FdrContinent'; name: string; id: string }
    | {
        __typename?: 'FdrCountryGroup';
        name: string;
        id: string;
        continent: { __typename?: 'FdrContinent'; name: string; id: string };
      }
    | {
        __typename?: 'FdrRegion';
        name: string;
        id: string;
        country: {
          __typename?: 'FdrCountry';
          name: string;
          id: string;
          countryGroup?: {
            __typename?: 'FdrCountryGroup';
            name: string;
            id: string;
            continent: {
              __typename?: 'FdrContinent';
              name: string;
              id: string;
            };
          } | null;
          continent: { __typename?: 'FdrContinent'; name: string; id: string };
        };
      }
    | {
        __typename?: 'FdrResort';
        name: string;
        id: string;
        destination: {
          __typename?: 'FdrDestination';
          name: string;
          id: string;
          region?: {
            __typename?: 'FdrRegion';
            name: string;
            id: string;
            country: {
              __typename?: 'FdrCountry';
              name: string;
              id: string;
              countryGroup?: {
                __typename?: 'FdrCountryGroup';
                name: string;
                id: string;
                continent: {
                  __typename?: 'FdrContinent';
                  name: string;
                  id: string;
                };
              } | null;
              continent: {
                __typename?: 'FdrContinent';
                name: string;
                id: string;
              };
            };
          } | null;
          country: {
            __typename?: 'FdrCountry';
            name: string;
            id: string;
            countryGroup?: {
              __typename?: 'FdrCountryGroup';
              name: string;
              id: string;
              continent: {
                __typename?: 'FdrContinent';
                name: string;
                id: string;
              };
            } | null;
            continent: {
              __typename?: 'FdrContinent';
              name: string;
              id: string;
            };
          };
        };
      };
};

export type FdrSearchControlProductDestinationsFragment = {
  __typename?: 'FdrSearchControlsProductDestination';
  product:
    | { __typename?: 'FdrCruise'; name: string; id: string }
    | {
        __typename?: 'FdrHotel';
        name: string;
        mythosCode?: string | null;
        id: string;
        resort: {
          __typename?: 'FdrResort';
          name: string;
          id: string;
          destination: {
            __typename?: 'FdrDestination';
            name: string;
            id: string;
            region?: {
              __typename?: 'FdrRegion';
              name: string;
              id: string;
              country: {
                __typename?: 'FdrCountry';
                name: string;
                id: string;
                countryGroup?: {
                  __typename?: 'FdrCountryGroup';
                  name: string;
                  id: string;
                  continent: {
                    __typename?: 'FdrContinent';
                    name: string;
                    id: string;
                  };
                } | null;
                continent: {
                  __typename?: 'FdrContinent';
                  name: string;
                  id: string;
                };
              };
            } | null;
            country: {
              __typename?: 'FdrCountry';
              name: string;
              id: string;
              countryGroup?: {
                __typename?: 'FdrCountryGroup';
                name: string;
                id: string;
                continent: {
                  __typename?: 'FdrContinent';
                  name: string;
                  id: string;
                };
              } | null;
              continent: {
                __typename?: 'FdrContinent';
                name: string;
                id: string;
              };
            };
          };
        };
      }
    | { __typename?: 'FdrRoundTrip'; name: string; id: string }
    | { __typename?: 'FdrAdventureTravel'; name: string; id: string };
};

export const FdrSearchControlProductDestinationsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrSearchControlProductDestinations' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchControlsProductDestination' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrHotel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrCruise' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrAdventureTravel' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrRoundTrip' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrHotelFragmentDoc.definitions,
    ...FdrCruiseFragmentDoc.definitions,
    ...FdrAdventureTravelFragmentDoc.definitions,
    ...FdrRoundTripFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrSearchControlGeoDestinationsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrSearchControlGeoDestinations' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchControlsGeoDestination' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'geo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrResort' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrCountry' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrContinent' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrRegion' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrDestination' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrCountryGroup' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrResortFragmentDoc.definitions,
    ...FdrCountryFragmentDoc.definitions,
    ...FdrContinentFragmentDoc.definitions,
    ...FdrRegionFragmentDoc.definitions,
    ...FdrDestinationFragmentDoc.definitions,
    ...FdrCountryGroupFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrSearchControlAirportDestinationsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrSearchControlAirportDestinations' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchControlsAirportDestination' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'airport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'iata' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'iata' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isGroup' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FdrSearchControlThemeDestinationsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrSearchControlThemeDestinations' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchControlsThemeDestination' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'theme' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FdrTravelDestinationsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrTravelDestinations' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchControlsTravelDestinations' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destinations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'fdrSearchControlProductDestinations',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'fdrSearchControlGeoDestinations',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'fdrSearchControlAirportDestinations',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'fdrSearchControlThemeDestinations',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'radiusSearch' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'center' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latitude' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longitude' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'radius' } },
              ],
            },
          },
        ],
      },
    },
    ...FdrSearchControlProductDestinationsFragmentDoc.definitions,
    ...FdrSearchControlGeoDestinationsFragmentDoc.definitions,
    ...FdrSearchControlAirportDestinationsFragmentDoc.definitions,
    ...FdrSearchControlThemeDestinationsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
