import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrTravellersFragmentDoc } from './fdr-travellers.generated';
import { FdrTravelPeriodFragmentDoc } from 'fdr/schemas/fragment/travel-dates/fdr-travel-period.generated';
import { FdrFiltersFragmentDoc } from 'fdr/schemas/fragment/filters/fdr-filters.generated';
import { FdrTravelDestinationsFragmentDoc } from 'fdr/schemas/fragment/destinations/fdr-travel-destinations.generated';
export type FdrSearchControlsFragment = {
  __typename?: 'FdrSearchControls';
  rooms: number;
  travelType: Types.FdrProductTravelType;
  sorting: {
    __typename?: 'FdrSortingControls';
    groupSorting: Types.FdrGroupSorting;
    offerSorting: Types.FdrOfferSorting;
    productSorting: Types.FdrProductSorting;
  };
  travellers: {
    __typename?: 'FdrSearchControlsTravellers';
    adults: number;
    childrenDobs?: Array<any> | null;
    roomDistribution?: Array<{
      __typename?: 'FdrSearchControlsTravellersDistribution';
      childrenDobs?: Array<any> | null;
      adults: number;
    }> | null;
  };
  travelPeriod: {
    __typename?: 'FdrSearchControlsTravelPeriod';
    defaultPeriod: Types.FdrTravelPeriodType;
    exactDepartureDate: any;
    exactReturnDate: any;
    flexibleDepartureDate: any;
    flexibleReturnDate: any;
    flexibleDurations: Array<number>;
  };
  filters?: Array<
    | {
        __typename: 'FdrSearchControlsMultiSelectFilter';
        visible: boolean;
        id: string;
        name?: string | null;
        options: Array<
          | {
              __typename: 'FdrSearchControlsAccommodationSizeOption';
              visible: boolean;
              id: string;
              selected: boolean;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsAirlineOption';
              visible: boolean;
              id: string;
              selected: boolean;
              airline?: {
                __typename?: 'FdrAirline';
                name: string;
                iata: string;
              } | null;
            }
          | {
              __typename: 'FdrSearchControlsDepartureAirportOption';
              visible: boolean;
              id: string;
              selected: boolean;
              caption?: string | null;
              airport?: {
                __typename?: 'FdrAirport';
                name: string;
                iata: string;
                codes?: Array<string> | null;
                isGroup: boolean;
              } | null;
            }
          | {
              __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
              visible: boolean;
              id: string;
              selected: boolean;
              from?: any | null;
              to?: any | null;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsFlightDurationOption';
              visible: boolean;
              id: string;
              selected: boolean;
              maxDuration?: number | null;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsFlightProviderOption';
              visible: boolean;
              id: string;
              selected: boolean;
            }
          | {
              __typename: 'FdrSearchControlsHpRatingOption';
              visible: boolean;
              id: string;
              selected: boolean;
              rating?: number | null;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsMaxPriceOption';
              visible: boolean;
              id: string;
              selected: boolean;
              maxPrice?: number | null;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsMaxStopsOption';
              visible: boolean;
              id: string;
              selected: boolean;
              caption?: string | null;
              value?: number | null;
            }
          | {
              __typename: 'FdrSearchControlsMealTypeOption';
              visible: boolean;
              id: string;
              selected: boolean;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsMinPriceOption';
              visible: boolean;
              id: string;
              selected: boolean;
              minPrice?: number | null;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsProductCodeOption';
              visible: boolean;
              id: string;
              selected: boolean;
            }
          | {
              __typename: 'FdrSearchControlsProductFeatureOption';
              visible: boolean;
              id: string;
              selected: boolean;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsProductProviderOption';
              visible: boolean;
              id: string;
              selected: boolean;
            }
          | {
              __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
              visible: boolean;
              id: string;
              selected: boolean;
              to?: any | null;
              from?: any | null;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsRoomTypeOption';
              visible: boolean;
              id: string;
              selected: boolean;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsTaRatingOption';
              visible: boolean;
              id: string;
              selected: boolean;
              rating?: number | null;
              caption?: string | null;
            }
        >;
      }
    | {
        __typename: 'FdrSearchControlsOneOptionFilter';
        visible: boolean;
        id: string;
        name?: string | null;
        option:
          | {
              __typename: 'FdrSearchControlsAccommodationSizeOption';
              visible: boolean;
              id: string;
              selected: boolean;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsAirlineOption';
              visible: boolean;
              id: string;
              selected: boolean;
              airline?: {
                __typename?: 'FdrAirline';
                name: string;
                iata: string;
              } | null;
            }
          | {
              __typename: 'FdrSearchControlsDepartureAirportOption';
              visible: boolean;
              id: string;
              selected: boolean;
              caption?: string | null;
              airport?: {
                __typename?: 'FdrAirport';
                name: string;
                iata: string;
                codes?: Array<string> | null;
                isGroup: boolean;
              } | null;
            }
          | {
              __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
              visible: boolean;
              id: string;
              selected: boolean;
              from?: any | null;
              to?: any | null;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsFlightDurationOption';
              visible: boolean;
              id: string;
              selected: boolean;
              maxDuration?: number | null;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsFlightProviderOption';
              visible: boolean;
              id: string;
              selected: boolean;
            }
          | {
              __typename: 'FdrSearchControlsHpRatingOption';
              visible: boolean;
              id: string;
              selected: boolean;
              rating?: number | null;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsMaxPriceOption';
              visible: boolean;
              id: string;
              selected: boolean;
              maxPrice?: number | null;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsMaxStopsOption';
              visible: boolean;
              id: string;
              selected: boolean;
              caption?: string | null;
              value?: number | null;
            }
          | {
              __typename: 'FdrSearchControlsMealTypeOption';
              visible: boolean;
              id: string;
              selected: boolean;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsMinPriceOption';
              visible: boolean;
              id: string;
              selected: boolean;
              minPrice?: number | null;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsProductCodeOption';
              visible: boolean;
              id: string;
              selected: boolean;
            }
          | {
              __typename: 'FdrSearchControlsProductFeatureOption';
              visible: boolean;
              id: string;
              selected: boolean;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsProductProviderOption';
              visible: boolean;
              id: string;
              selected: boolean;
            }
          | {
              __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
              visible: boolean;
              id: string;
              selected: boolean;
              to?: any | null;
              from?: any | null;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsRoomTypeOption';
              visible: boolean;
              id: string;
              selected: boolean;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsTaRatingOption';
              visible: boolean;
              id: string;
              selected: boolean;
              rating?: number | null;
              caption?: string | null;
            };
      }
    | {
        __typename: 'FdrSearchControlsSingleSelectFilter';
        visible: boolean;
        id: string;
        name?: string | null;
        options: Array<
          | {
              __typename: 'FdrSearchControlsAccommodationSizeOption';
              visible: boolean;
              id: string;
              selected: boolean;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsAirlineOption';
              visible: boolean;
              id: string;
              selected: boolean;
              airline?: {
                __typename?: 'FdrAirline';
                name: string;
                iata: string;
              } | null;
            }
          | {
              __typename: 'FdrSearchControlsDepartureAirportOption';
              visible: boolean;
              id: string;
              selected: boolean;
              caption?: string | null;
              airport?: {
                __typename?: 'FdrAirport';
                name: string;
                iata: string;
                codes?: Array<string> | null;
                isGroup: boolean;
              } | null;
            }
          | {
              __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
              visible: boolean;
              id: string;
              selected: boolean;
              from?: any | null;
              to?: any | null;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsFlightDurationOption';
              visible: boolean;
              id: string;
              selected: boolean;
              maxDuration?: number | null;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsFlightProviderOption';
              visible: boolean;
              id: string;
              selected: boolean;
            }
          | {
              __typename: 'FdrSearchControlsHpRatingOption';
              visible: boolean;
              id: string;
              selected: boolean;
              rating?: number | null;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsMaxPriceOption';
              visible: boolean;
              id: string;
              selected: boolean;
              maxPrice?: number | null;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsMaxStopsOption';
              visible: boolean;
              id: string;
              selected: boolean;
              caption?: string | null;
              value?: number | null;
            }
          | {
              __typename: 'FdrSearchControlsMealTypeOption';
              visible: boolean;
              id: string;
              selected: boolean;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsMinPriceOption';
              visible: boolean;
              id: string;
              selected: boolean;
              minPrice?: number | null;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsProductCodeOption';
              visible: boolean;
              id: string;
              selected: boolean;
            }
          | {
              __typename: 'FdrSearchControlsProductFeatureOption';
              visible: boolean;
              id: string;
              selected: boolean;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsProductProviderOption';
              visible: boolean;
              id: string;
              selected: boolean;
            }
          | {
              __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
              visible: boolean;
              id: string;
              selected: boolean;
              to?: any | null;
              from?: any | null;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsRoomTypeOption';
              visible: boolean;
              id: string;
              selected: boolean;
              caption?: string | null;
            }
          | {
              __typename: 'FdrSearchControlsTaRatingOption';
              visible: boolean;
              id: string;
              selected: boolean;
              rating?: number | null;
              caption?: string | null;
            }
        >;
      }
  > | null;
  travelDestinations?: {
    __typename?: 'FdrSearchControlsTravelDestinations';
    selectedSubGeo?: Array<string> | null;
    destinations?: Array<
      | {
          __typename?: 'FdrSearchControlsAirportDestination';
          airport: {
            __typename?: 'FdrAirport';
            iata: string;
            name: string;
            codes?: Array<string> | null;
            isGroup: boolean;
            id: string;
          };
        }
      | {
          __typename?: 'FdrSearchControlsGeoDestination';
          geo:
            | {
                __typename?: 'FdrDestination';
                name: string;
                id: string;
                region?: {
                  __typename?: 'FdrRegion';
                  name: string;
                  id: string;
                  country: {
                    __typename?: 'FdrCountry';
                    name: string;
                    id: string;
                    countryGroup?: {
                      __typename?: 'FdrCountryGroup';
                      name: string;
                      id: string;
                      continent: {
                        __typename?: 'FdrContinent';
                        name: string;
                        id: string;
                      };
                    } | null;
                    continent: {
                      __typename?: 'FdrContinent';
                      name: string;
                      id: string;
                    };
                  };
                } | null;
                country: {
                  __typename?: 'FdrCountry';
                  name: string;
                  id: string;
                  countryGroup?: {
                    __typename?: 'FdrCountryGroup';
                    name: string;
                    id: string;
                    continent: {
                      __typename?: 'FdrContinent';
                      name: string;
                      id: string;
                    };
                  } | null;
                  continent: {
                    __typename?: 'FdrContinent';
                    name: string;
                    id: string;
                  };
                };
              }
            | {
                __typename?: 'FdrCountry';
                name: string;
                id: string;
                countryGroup?: {
                  __typename?: 'FdrCountryGroup';
                  name: string;
                  id: string;
                  continent: {
                    __typename?: 'FdrContinent';
                    name: string;
                    id: string;
                  };
                } | null;
                continent: {
                  __typename?: 'FdrContinent';
                  name: string;
                  id: string;
                };
              }
            | { __typename?: 'FdrContinent'; name: string; id: string }
            | {
                __typename?: 'FdrCountryGroup';
                name: string;
                id: string;
                continent: {
                  __typename?: 'FdrContinent';
                  name: string;
                  id: string;
                };
              }
            | {
                __typename?: 'FdrRegion';
                name: string;
                id: string;
                country: {
                  __typename?: 'FdrCountry';
                  name: string;
                  id: string;
                  countryGroup?: {
                    __typename?: 'FdrCountryGroup';
                    name: string;
                    id: string;
                    continent: {
                      __typename?: 'FdrContinent';
                      name: string;
                      id: string;
                    };
                  } | null;
                  continent: {
                    __typename?: 'FdrContinent';
                    name: string;
                    id: string;
                  };
                };
              }
            | {
                __typename?: 'FdrResort';
                name: string;
                id: string;
                destination: {
                  __typename?: 'FdrDestination';
                  name: string;
                  id: string;
                  region?: {
                    __typename?: 'FdrRegion';
                    name: string;
                    id: string;
                    country: {
                      __typename?: 'FdrCountry';
                      name: string;
                      id: string;
                      countryGroup?: {
                        __typename?: 'FdrCountryGroup';
                        name: string;
                        id: string;
                        continent: {
                          __typename?: 'FdrContinent';
                          name: string;
                          id: string;
                        };
                      } | null;
                      continent: {
                        __typename?: 'FdrContinent';
                        name: string;
                        id: string;
                      };
                    };
                  } | null;
                  country: {
                    __typename?: 'FdrCountry';
                    name: string;
                    id: string;
                    countryGroup?: {
                      __typename?: 'FdrCountryGroup';
                      name: string;
                      id: string;
                      continent: {
                        __typename?: 'FdrContinent';
                        name: string;
                        id: string;
                      };
                    } | null;
                    continent: {
                      __typename?: 'FdrContinent';
                      name: string;
                      id: string;
                    };
                  };
                };
              };
        }
      | {
          __typename?: 'FdrSearchControlsProductDestination';
          product:
            | { __typename?: 'FdrCruise'; name: string; id: string }
            | {
                __typename?: 'FdrHotel';
                name: string;
                mythosCode?: string | null;
                id: string;
                resort: {
                  __typename?: 'FdrResort';
                  name: string;
                  id: string;
                  destination: {
                    __typename?: 'FdrDestination';
                    name: string;
                    id: string;
                    region?: {
                      __typename?: 'FdrRegion';
                      name: string;
                      id: string;
                      country: {
                        __typename?: 'FdrCountry';
                        name: string;
                        id: string;
                        countryGroup?: {
                          __typename?: 'FdrCountryGroup';
                          name: string;
                          id: string;
                          continent: {
                            __typename?: 'FdrContinent';
                            name: string;
                            id: string;
                          };
                        } | null;
                        continent: {
                          __typename?: 'FdrContinent';
                          name: string;
                          id: string;
                        };
                      };
                    } | null;
                    country: {
                      __typename?: 'FdrCountry';
                      name: string;
                      id: string;
                      countryGroup?: {
                        __typename?: 'FdrCountryGroup';
                        name: string;
                        id: string;
                        continent: {
                          __typename?: 'FdrContinent';
                          name: string;
                          id: string;
                        };
                      } | null;
                      continent: {
                        __typename?: 'FdrContinent';
                        name: string;
                        id: string;
                      };
                    };
                  };
                };
              }
            | { __typename?: 'FdrRoundTrip'; name: string; id: string }
            | { __typename?: 'FdrAdventureTravel'; name: string; id: string };
        }
      | {
          __typename?: 'FdrSearchControlsThemeDestination';
          theme: { __typename?: 'FdrTheme'; name: string; id: string };
        }
    > | null;
    radiusSearch?: {
      __typename?: 'FdrSearchControlsRadiusSearch';
      label: string;
      radius: number;
      center: {
        __typename?: 'FdrCoordinates';
        latitude: number;
        longitude: number;
      };
    } | null;
  } | null;
};

export type FdrSearchControlsLightFragment = {
  __typename?: 'FdrSearchControls';
  rooms: number;
  travelType: Types.FdrProductTravelType;
  travellers: {
    __typename?: 'FdrSearchControlsTravellers';
    adults: number;
    childrenDobs?: Array<any> | null;
    roomDistribution?: Array<{
      __typename?: 'FdrSearchControlsTravellersDistribution';
      childrenDobs?: Array<any> | null;
      adults: number;
    }> | null;
  };
  travelPeriod: {
    __typename?: 'FdrSearchControlsTravelPeriod';
    defaultPeriod: Types.FdrTravelPeriodType;
    exactDepartureDate: any;
    exactReturnDate: any;
    flexibleDepartureDate: any;
    flexibleReturnDate: any;
    flexibleDurations: Array<number>;
  };
  travelDestinations?: {
    __typename?: 'FdrSearchControlsTravelDestinations';
    selectedSubGeo?: Array<string> | null;
    destinations?: Array<
      | {
          __typename?: 'FdrSearchControlsAirportDestination';
          airport: {
            __typename?: 'FdrAirport';
            iata: string;
            name: string;
            codes?: Array<string> | null;
            isGroup: boolean;
            id: string;
          };
        }
      | {
          __typename?: 'FdrSearchControlsGeoDestination';
          geo:
            | {
                __typename?: 'FdrDestination';
                name: string;
                id: string;
                region?: {
                  __typename?: 'FdrRegion';
                  name: string;
                  id: string;
                  country: {
                    __typename?: 'FdrCountry';
                    name: string;
                    id: string;
                    countryGroup?: {
                      __typename?: 'FdrCountryGroup';
                      name: string;
                      id: string;
                      continent: {
                        __typename?: 'FdrContinent';
                        name: string;
                        id: string;
                      };
                    } | null;
                    continent: {
                      __typename?: 'FdrContinent';
                      name: string;
                      id: string;
                    };
                  };
                } | null;
                country: {
                  __typename?: 'FdrCountry';
                  name: string;
                  id: string;
                  countryGroup?: {
                    __typename?: 'FdrCountryGroup';
                    name: string;
                    id: string;
                    continent: {
                      __typename?: 'FdrContinent';
                      name: string;
                      id: string;
                    };
                  } | null;
                  continent: {
                    __typename?: 'FdrContinent';
                    name: string;
                    id: string;
                  };
                };
              }
            | {
                __typename?: 'FdrCountry';
                name: string;
                id: string;
                countryGroup?: {
                  __typename?: 'FdrCountryGroup';
                  name: string;
                  id: string;
                  continent: {
                    __typename?: 'FdrContinent';
                    name: string;
                    id: string;
                  };
                } | null;
                continent: {
                  __typename?: 'FdrContinent';
                  name: string;
                  id: string;
                };
              }
            | { __typename?: 'FdrContinent'; name: string; id: string }
            | {
                __typename?: 'FdrCountryGroup';
                name: string;
                id: string;
                continent: {
                  __typename?: 'FdrContinent';
                  name: string;
                  id: string;
                };
              }
            | {
                __typename?: 'FdrRegion';
                name: string;
                id: string;
                country: {
                  __typename?: 'FdrCountry';
                  name: string;
                  id: string;
                  countryGroup?: {
                    __typename?: 'FdrCountryGroup';
                    name: string;
                    id: string;
                    continent: {
                      __typename?: 'FdrContinent';
                      name: string;
                      id: string;
                    };
                  } | null;
                  continent: {
                    __typename?: 'FdrContinent';
                    name: string;
                    id: string;
                  };
                };
              }
            | {
                __typename?: 'FdrResort';
                name: string;
                id: string;
                destination: {
                  __typename?: 'FdrDestination';
                  name: string;
                  id: string;
                  region?: {
                    __typename?: 'FdrRegion';
                    name: string;
                    id: string;
                    country: {
                      __typename?: 'FdrCountry';
                      name: string;
                      id: string;
                      countryGroup?: {
                        __typename?: 'FdrCountryGroup';
                        name: string;
                        id: string;
                        continent: {
                          __typename?: 'FdrContinent';
                          name: string;
                          id: string;
                        };
                      } | null;
                      continent: {
                        __typename?: 'FdrContinent';
                        name: string;
                        id: string;
                      };
                    };
                  } | null;
                  country: {
                    __typename?: 'FdrCountry';
                    name: string;
                    id: string;
                    countryGroup?: {
                      __typename?: 'FdrCountryGroup';
                      name: string;
                      id: string;
                      continent: {
                        __typename?: 'FdrContinent';
                        name: string;
                        id: string;
                      };
                    } | null;
                    continent: {
                      __typename?: 'FdrContinent';
                      name: string;
                      id: string;
                    };
                  };
                };
              };
        }
      | {
          __typename?: 'FdrSearchControlsProductDestination';
          product:
            | { __typename?: 'FdrCruise'; name: string; id: string }
            | {
                __typename?: 'FdrHotel';
                name: string;
                mythosCode?: string | null;
                id: string;
                resort: {
                  __typename?: 'FdrResort';
                  name: string;
                  id: string;
                  destination: {
                    __typename?: 'FdrDestination';
                    name: string;
                    id: string;
                    region?: {
                      __typename?: 'FdrRegion';
                      name: string;
                      id: string;
                      country: {
                        __typename?: 'FdrCountry';
                        name: string;
                        id: string;
                        countryGroup?: {
                          __typename?: 'FdrCountryGroup';
                          name: string;
                          id: string;
                          continent: {
                            __typename?: 'FdrContinent';
                            name: string;
                            id: string;
                          };
                        } | null;
                        continent: {
                          __typename?: 'FdrContinent';
                          name: string;
                          id: string;
                        };
                      };
                    } | null;
                    country: {
                      __typename?: 'FdrCountry';
                      name: string;
                      id: string;
                      countryGroup?: {
                        __typename?: 'FdrCountryGroup';
                        name: string;
                        id: string;
                        continent: {
                          __typename?: 'FdrContinent';
                          name: string;
                          id: string;
                        };
                      } | null;
                      continent: {
                        __typename?: 'FdrContinent';
                        name: string;
                        id: string;
                      };
                    };
                  };
                };
              }
            | { __typename?: 'FdrRoundTrip'; name: string; id: string }
            | { __typename?: 'FdrAdventureTravel'; name: string; id: string };
        }
      | {
          __typename?: 'FdrSearchControlsThemeDestination';
          theme: { __typename?: 'FdrTheme'; name: string; id: string };
        }
    > | null;
    radiusSearch?: {
      __typename?: 'FdrSearchControlsRadiusSearch';
      label: string;
      radius: number;
      center: {
        __typename?: 'FdrCoordinates';
        latitude: number;
        longitude: number;
      };
    } | null;
  } | null;
};

export const FdrSearchControlsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrSearchControls' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchControls' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'rooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'travelType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sorting' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupSorting' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'offerSorting' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'productSorting' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'travellers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrTravellers' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'travelPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrTravelPeriod' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrFilters' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'travelDestinations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'selectedSubGeo' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrTravelDestinations' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrTravellersFragmentDoc.definitions,
    ...FdrTravelPeriodFragmentDoc.definitions,
    ...FdrFiltersFragmentDoc.definitions,
    ...FdrTravelDestinationsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrSearchControlsLightFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrSearchControlsLight' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchControls' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'rooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'travelType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'travellers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrTravellers' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'travelPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrTravelPeriod' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'travelDestinations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'selectedSubGeo' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrTravelDestinations' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrTravellersFragmentDoc.definitions,
    ...FdrTravelPeriodFragmentDoc.definitions,
    ...FdrTravelDestinationsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
