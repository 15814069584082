import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
export type FdrTravellersFragment = {
  __typename?: 'FdrSearchControlsTravellers';
  adults: number;
  childrenDobs?: Array<any> | null;
  roomDistribution?: Array<{
    __typename?: 'FdrSearchControlsTravellersDistribution';
    childrenDobs?: Array<any> | null;
    adults: number;
  }> | null;
};

export const FdrTravellersFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrTravellers' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchControlsTravellers' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'adults' } },
          { kind: 'Field', name: { kind: 'Name', value: 'childrenDobs' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roomDistribution' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'childrenDobs' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'adults' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
