import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrCountryFragmentDoc } from './fdr-country.generated';
export type FdrRegionFragment = {
  __typename?: 'FdrRegion';
  name: string;
  id: string;
  country: {
    __typename?: 'FdrCountry';
    name: string;
    id: string;
    countryGroup?: {
      __typename?: 'FdrCountryGroup';
      name: string;
      id: string;
      continent: { __typename?: 'FdrContinent'; name: string; id: string };
    } | null;
    continent: { __typename?: 'FdrContinent'; name: string; id: string };
  };
};

export const FdrRegionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrRegion' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrRegion' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'id' },
            name: { kind: 'Name', value: 'publicId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrCountry' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrCountryFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
