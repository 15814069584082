import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrDestinationFragmentDoc } from 'fdr/schemas/fragment/geo/fdr-destination.generated';
export type FdrHotelFragment = {
  __typename?: 'FdrHotel';
  name: string;
  mythosCode?: string | null;
  id: string;
  resort: {
    __typename?: 'FdrResort';
    name: string;
    id: string;
    destination: {
      __typename?: 'FdrDestination';
      name: string;
      id: string;
      region?: {
        __typename?: 'FdrRegion';
        name: string;
        id: string;
        country: {
          __typename?: 'FdrCountry';
          name: string;
          id: string;
          countryGroup?: {
            __typename?: 'FdrCountryGroup';
            name: string;
            id: string;
            continent: {
              __typename?: 'FdrContinent';
              name: string;
              id: string;
            };
          } | null;
          continent: { __typename?: 'FdrContinent'; name: string; id: string };
        };
      } | null;
      country: {
        __typename?: 'FdrCountry';
        name: string;
        id: string;
        countryGroup?: {
          __typename?: 'FdrCountryGroup';
          name: string;
          id: string;
          continent: { __typename?: 'FdrContinent'; name: string; id: string };
        } | null;
        continent: { __typename?: 'FdrContinent'; name: string; id: string };
      };
    };
  };
};

export const FdrHotelFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrHotel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrHotel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'id' },
            name: { kind: 'Name', value: 'publicId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mythosCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resort' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'destination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrDestination' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrDestinationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
