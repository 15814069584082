import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
export type FdrFiltersFdrSearchControlsMultiSelectFilterFragment = {
  __typename: 'FdrSearchControlsMultiSelectFilter';
  visible: boolean;
  id: string;
  name?: string | null;
  options: Array<
    | {
        __typename: 'FdrSearchControlsAccommodationSizeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsAirlineOption';
        visible: boolean;
        id: string;
        selected: boolean;
        airline?: {
          __typename?: 'FdrAirline';
          name: string;
          iata: string;
        } | null;
      }
    | {
        __typename: 'FdrSearchControlsDepartureAirportOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
        airport?: {
          __typename?: 'FdrAirport';
          name: string;
          iata: string;
          codes?: Array<string> | null;
          isGroup: boolean;
        } | null;
      }
    | {
        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        from?: any | null;
        to?: any | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsFlightDurationOption';
        visible: boolean;
        id: string;
        selected: boolean;
        maxDuration?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsFlightProviderOption';
        visible: boolean;
        id: string;
        selected: boolean;
      }
    | {
        __typename: 'FdrSearchControlsHpRatingOption';
        visible: boolean;
        id: string;
        selected: boolean;
        rating?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsMaxPriceOption';
        visible: boolean;
        id: string;
        selected: boolean;
        maxPrice?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsMaxStopsOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
        value?: number | null;
      }
    | {
        __typename: 'FdrSearchControlsMealTypeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsMinPriceOption';
        visible: boolean;
        id: string;
        selected: boolean;
        minPrice?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsProductCodeOption';
        visible: boolean;
        id: string;
        selected: boolean;
      }
    | {
        __typename: 'FdrSearchControlsProductFeatureOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsProductProviderOption';
        visible: boolean;
        id: string;
        selected: boolean;
      }
    | {
        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        to?: any | null;
        from?: any | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsRoomTypeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsTaRatingOption';
        visible: boolean;
        id: string;
        selected: boolean;
        rating?: number | null;
        caption?: string | null;
      }
  >;
};

export type FdrFiltersFdrSearchControlsOneOptionFilterFragment = {
  __typename: 'FdrSearchControlsOneOptionFilter';
  visible: boolean;
  id: string;
  name?: string | null;
  option:
    | {
        __typename: 'FdrSearchControlsAccommodationSizeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsAirlineOption';
        visible: boolean;
        id: string;
        selected: boolean;
        airline?: {
          __typename?: 'FdrAirline';
          name: string;
          iata: string;
        } | null;
      }
    | {
        __typename: 'FdrSearchControlsDepartureAirportOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
        airport?: {
          __typename?: 'FdrAirport';
          name: string;
          iata: string;
          codes?: Array<string> | null;
          isGroup: boolean;
        } | null;
      }
    | {
        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        from?: any | null;
        to?: any | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsFlightDurationOption';
        visible: boolean;
        id: string;
        selected: boolean;
        maxDuration?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsFlightProviderOption';
        visible: boolean;
        id: string;
        selected: boolean;
      }
    | {
        __typename: 'FdrSearchControlsHpRatingOption';
        visible: boolean;
        id: string;
        selected: boolean;
        rating?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsMaxPriceOption';
        visible: boolean;
        id: string;
        selected: boolean;
        maxPrice?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsMaxStopsOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
        value?: number | null;
      }
    | {
        __typename: 'FdrSearchControlsMealTypeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsMinPriceOption';
        visible: boolean;
        id: string;
        selected: boolean;
        minPrice?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsProductCodeOption';
        visible: boolean;
        id: string;
        selected: boolean;
      }
    | {
        __typename: 'FdrSearchControlsProductFeatureOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsProductProviderOption';
        visible: boolean;
        id: string;
        selected: boolean;
      }
    | {
        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        to?: any | null;
        from?: any | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsRoomTypeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsTaRatingOption';
        visible: boolean;
        id: string;
        selected: boolean;
        rating?: number | null;
        caption?: string | null;
      };
};

export type FdrFiltersFdrSearchControlsSingleSelectFilterFragment = {
  __typename: 'FdrSearchControlsSingleSelectFilter';
  visible: boolean;
  id: string;
  name?: string | null;
  options: Array<
    | {
        __typename: 'FdrSearchControlsAccommodationSizeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsAirlineOption';
        visible: boolean;
        id: string;
        selected: boolean;
        airline?: {
          __typename?: 'FdrAirline';
          name: string;
          iata: string;
        } | null;
      }
    | {
        __typename: 'FdrSearchControlsDepartureAirportOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
        airport?: {
          __typename?: 'FdrAirport';
          name: string;
          iata: string;
          codes?: Array<string> | null;
          isGroup: boolean;
        } | null;
      }
    | {
        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        from?: any | null;
        to?: any | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsFlightDurationOption';
        visible: boolean;
        id: string;
        selected: boolean;
        maxDuration?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsFlightProviderOption';
        visible: boolean;
        id: string;
        selected: boolean;
      }
    | {
        __typename: 'FdrSearchControlsHpRatingOption';
        visible: boolean;
        id: string;
        selected: boolean;
        rating?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsMaxPriceOption';
        visible: boolean;
        id: string;
        selected: boolean;
        maxPrice?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsMaxStopsOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
        value?: number | null;
      }
    | {
        __typename: 'FdrSearchControlsMealTypeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsMinPriceOption';
        visible: boolean;
        id: string;
        selected: boolean;
        minPrice?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsProductCodeOption';
        visible: boolean;
        id: string;
        selected: boolean;
      }
    | {
        __typename: 'FdrSearchControlsProductFeatureOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsProductProviderOption';
        visible: boolean;
        id: string;
        selected: boolean;
      }
    | {
        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        to?: any | null;
        from?: any | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsRoomTypeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsTaRatingOption';
        visible: boolean;
        id: string;
        selected: boolean;
        rating?: number | null;
        caption?: string | null;
      }
  >;
};

export type FdrFiltersFragment =
  | FdrFiltersFdrSearchControlsMultiSelectFilterFragment
  | FdrFiltersFdrSearchControlsOneOptionFilterFragment
  | FdrFiltersFdrSearchControlsSingleSelectFilterFragment;

export type FdrFlightFiltersFdrFlightSearchControlsMultiSelectFilterFragment = {
  __typename: 'FdrFlightSearchControlsMultiSelectFilter';
  visible: boolean;
  id: string;
  name?: string | null;
  options: Array<
    | {
        __typename: 'FdrSearchControlsAirlineOption';
        visible: boolean;
        id: string;
        selected: boolean;
        airline?: {
          __typename?: 'FdrAirline';
          name: string;
          iata: string;
        } | null;
      }
    | {
        __typename: 'FdrSearchControlsDepartureFlightArrivalTimeOption';
        selected: boolean;
        id: string;
        visible: boolean;
        from?: any | null;
        to?: any | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
        selected: boolean;
        id: string;
        visible: boolean;
        from?: any | null;
        to?: any | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsFlightStopoverDurationOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
        availableRange?: {
          __typename?: 'FdrFlightStopoverDurationRange';
          minDuration?: number | null;
          maxDuration?: number | null;
        } | null;
        selectedRange?: {
          __typename?: 'FdrFlightStopoverDurationRange';
          minDuration?: number | null;
          maxDuration?: number | null;
        } | null;
      }
    | {
        __typename: 'FdrSearchControlsMaxPriceOption';
        selected: boolean;
        id: string;
        visible: boolean;
        maxPrice?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsMaxStopsOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
        value?: number | null;
      }
    | {
        __typename: 'FdrSearchControlsMinPriceOption';
        selected: boolean;
        id: string;
        visible: boolean;
        minPrice?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsReturnFlightArrivalTimeOption';
        selected: boolean;
        id: string;
        visible: boolean;
        from?: any | null;
        to?: any | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
        selected: boolean;
        id: string;
        visible: boolean;
        to?: any | null;
        from?: any | null;
        caption?: string | null;
      }
  >;
};

export type FdrFlightFiltersFdrFlightSearchControlsOneOptionFilterFragment = {
  __typename: 'FdrFlightSearchControlsOneOptionFilter';
  visible: boolean;
  id: string;
  name?: string | null;
  option:
    | {
        __typename: 'FdrSearchControlsAirlineOption';
        visible: boolean;
        id: string;
        selected: boolean;
        airline?: {
          __typename?: 'FdrAirline';
          name: string;
          iata: string;
        } | null;
      }
    | {
        __typename: 'FdrSearchControlsDepartureFlightArrivalTimeOption';
        selected: boolean;
        id: string;
        visible: boolean;
        from?: any | null;
        to?: any | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
        selected: boolean;
        id: string;
        visible: boolean;
        from?: any | null;
        to?: any | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsFlightStopoverDurationOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
        availableRange?: {
          __typename?: 'FdrFlightStopoverDurationRange';
          minDuration?: number | null;
          maxDuration?: number | null;
        } | null;
        selectedRange?: {
          __typename?: 'FdrFlightStopoverDurationRange';
          minDuration?: number | null;
          maxDuration?: number | null;
        } | null;
      }
    | {
        __typename: 'FdrSearchControlsMaxPriceOption';
        selected: boolean;
        id: string;
        visible: boolean;
        maxPrice?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsMaxStopsOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
        value?: number | null;
      }
    | {
        __typename: 'FdrSearchControlsMinPriceOption';
        selected: boolean;
        id: string;
        visible: boolean;
        minPrice?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsReturnFlightArrivalTimeOption';
        selected: boolean;
        id: string;
        visible: boolean;
        from?: any | null;
        to?: any | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
        selected: boolean;
        id: string;
        visible: boolean;
        to?: any | null;
        from?: any | null;
        caption?: string | null;
      };
};

export type FdrFlightFiltersFdrFlightSearchControlsSingleSelectFilterFragment =
  {
    __typename: 'FdrFlightSearchControlsSingleSelectFilter';
    visible: boolean;
    id: string;
    name?: string | null;
    options: Array<
      | {
          __typename: 'FdrSearchControlsAirlineOption';
          visible: boolean;
          id: string;
          selected: boolean;
          airline?: {
            __typename?: 'FdrAirline';
            name: string;
            iata: string;
          } | null;
        }
      | {
          __typename: 'FdrSearchControlsDepartureFlightArrivalTimeOption';
          selected: boolean;
          id: string;
          visible: boolean;
          from?: any | null;
          to?: any | null;
          caption?: string | null;
        }
      | {
          __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
          selected: boolean;
          id: string;
          visible: boolean;
          from?: any | null;
          to?: any | null;
          caption?: string | null;
        }
      | {
          __typename: 'FdrSearchControlsFlightStopoverDurationOption';
          visible: boolean;
          id: string;
          selected: boolean;
          caption?: string | null;
          availableRange?: {
            __typename?: 'FdrFlightStopoverDurationRange';
            minDuration?: number | null;
            maxDuration?: number | null;
          } | null;
          selectedRange?: {
            __typename?: 'FdrFlightStopoverDurationRange';
            minDuration?: number | null;
            maxDuration?: number | null;
          } | null;
        }
      | {
          __typename: 'FdrSearchControlsMaxPriceOption';
          selected: boolean;
          id: string;
          visible: boolean;
          maxPrice?: number | null;
          caption?: string | null;
        }
      | {
          __typename: 'FdrSearchControlsMaxStopsOption';
          visible: boolean;
          id: string;
          selected: boolean;
          caption?: string | null;
          value?: number | null;
        }
      | {
          __typename: 'FdrSearchControlsMinPriceOption';
          selected: boolean;
          id: string;
          visible: boolean;
          minPrice?: number | null;
          caption?: string | null;
        }
      | {
          __typename: 'FdrSearchControlsReturnFlightArrivalTimeOption';
          selected: boolean;
          id: string;
          visible: boolean;
          from?: any | null;
          to?: any | null;
          caption?: string | null;
        }
      | {
          __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
          selected: boolean;
          id: string;
          visible: boolean;
          to?: any | null;
          from?: any | null;
          caption?: string | null;
        }
    >;
  };

export type FdrFlightFiltersFragment =
  | FdrFlightFiltersFdrFlightSearchControlsMultiSelectFilterFragment
  | FdrFlightFiltersFdrFlightSearchControlsOneOptionFilterFragment
  | FdrFlightFiltersFdrFlightSearchControlsSingleSelectFilterFragment;

export type FdrOneOptionFilterFragment = {
  __typename: 'FdrSearchControlsOneOptionFilter';
  id: string;
  visible: boolean;
  name?: string | null;
  option:
    | {
        __typename: 'FdrSearchControlsAccommodationSizeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsAirlineOption';
        visible: boolean;
        id: string;
        selected: boolean;
        airline?: {
          __typename?: 'FdrAirline';
          name: string;
          iata: string;
        } | null;
      }
    | {
        __typename: 'FdrSearchControlsDepartureAirportOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
        airport?: {
          __typename?: 'FdrAirport';
          name: string;
          iata: string;
          codes?: Array<string> | null;
          isGroup: boolean;
        } | null;
      }
    | {
        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        from?: any | null;
        to?: any | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsFlightDurationOption';
        visible: boolean;
        id: string;
        selected: boolean;
        maxDuration?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsFlightProviderOption';
        visible: boolean;
        id: string;
        selected: boolean;
      }
    | {
        __typename: 'FdrSearchControlsHpRatingOption';
        visible: boolean;
        id: string;
        selected: boolean;
        rating?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsMaxPriceOption';
        visible: boolean;
        id: string;
        selected: boolean;
        maxPrice?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsMaxStopsOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
        value?: number | null;
      }
    | {
        __typename: 'FdrSearchControlsMealTypeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsMinPriceOption';
        visible: boolean;
        id: string;
        selected: boolean;
        minPrice?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsProductCodeOption';
        visible: boolean;
        id: string;
        selected: boolean;
      }
    | {
        __typename: 'FdrSearchControlsProductFeatureOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsProductProviderOption';
        visible: boolean;
        id: string;
        selected: boolean;
      }
    | {
        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        to?: any | null;
        from?: any | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsRoomTypeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsTaRatingOption';
        visible: boolean;
        id: string;
        selected: boolean;
        rating?: number | null;
        caption?: string | null;
      };
};

export type FdrSingleSelectFilterFragment = {
  __typename: 'FdrSearchControlsSingleSelectFilter';
  id: string;
  visible: boolean;
  name?: string | null;
  options: Array<
    | {
        __typename: 'FdrSearchControlsAccommodationSizeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsAirlineOption';
        visible: boolean;
        id: string;
        selected: boolean;
        airline?: {
          __typename?: 'FdrAirline';
          name: string;
          iata: string;
        } | null;
      }
    | {
        __typename: 'FdrSearchControlsDepartureAirportOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
        airport?: {
          __typename?: 'FdrAirport';
          name: string;
          iata: string;
          codes?: Array<string> | null;
          isGroup: boolean;
        } | null;
      }
    | {
        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        from?: any | null;
        to?: any | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsFlightDurationOption';
        visible: boolean;
        id: string;
        selected: boolean;
        maxDuration?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsFlightProviderOption';
        visible: boolean;
        id: string;
        selected: boolean;
      }
    | {
        __typename: 'FdrSearchControlsHpRatingOption';
        visible: boolean;
        id: string;
        selected: boolean;
        rating?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsMaxPriceOption';
        visible: boolean;
        id: string;
        selected: boolean;
        maxPrice?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsMaxStopsOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
        value?: number | null;
      }
    | {
        __typename: 'FdrSearchControlsMealTypeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsMinPriceOption';
        visible: boolean;
        id: string;
        selected: boolean;
        minPrice?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsProductCodeOption';
        visible: boolean;
        id: string;
        selected: boolean;
      }
    | {
        __typename: 'FdrSearchControlsProductFeatureOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsProductProviderOption';
        visible: boolean;
        id: string;
        selected: boolean;
      }
    | {
        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        to?: any | null;
        from?: any | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsRoomTypeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsTaRatingOption';
        visible: boolean;
        id: string;
        selected: boolean;
        rating?: number | null;
        caption?: string | null;
      }
  >;
};

export type FdrMultiSelectFilterFragment = {
  __typename: 'FdrSearchControlsMultiSelectFilter';
  id: string;
  visible: boolean;
  name?: string | null;
  options: Array<
    | {
        __typename: 'FdrSearchControlsAccommodationSizeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsAirlineOption';
        visible: boolean;
        id: string;
        selected: boolean;
        airline?: {
          __typename?: 'FdrAirline';
          name: string;
          iata: string;
        } | null;
      }
    | {
        __typename: 'FdrSearchControlsDepartureAirportOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
        airport?: {
          __typename?: 'FdrAirport';
          name: string;
          iata: string;
          codes?: Array<string> | null;
          isGroup: boolean;
        } | null;
      }
    | {
        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        from?: any | null;
        to?: any | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsFlightDurationOption';
        visible: boolean;
        id: string;
        selected: boolean;
        maxDuration?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsFlightProviderOption';
        visible: boolean;
        id: string;
        selected: boolean;
      }
    | {
        __typename: 'FdrSearchControlsHpRatingOption';
        visible: boolean;
        id: string;
        selected: boolean;
        rating?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsMaxPriceOption';
        visible: boolean;
        id: string;
        selected: boolean;
        maxPrice?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsMaxStopsOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
        value?: number | null;
      }
    | {
        __typename: 'FdrSearchControlsMealTypeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsMinPriceOption';
        visible: boolean;
        id: string;
        selected: boolean;
        minPrice?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsProductCodeOption';
        visible: boolean;
        id: string;
        selected: boolean;
      }
    | {
        __typename: 'FdrSearchControlsProductFeatureOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsProductProviderOption';
        visible: boolean;
        id: string;
        selected: boolean;
      }
    | {
        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        to?: any | null;
        from?: any | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsRoomTypeOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsTaRatingOption';
        visible: boolean;
        id: string;
        selected: boolean;
        rating?: number | null;
        caption?: string | null;
      }
  >;
};

export type FdrFlightOneOptionFilterFragment = {
  __typename: 'FdrFlightSearchControlsOneOptionFilter';
  id: string;
  visible: boolean;
  name?: string | null;
  option:
    | {
        __typename: 'FdrSearchControlsAirlineOption';
        visible: boolean;
        id: string;
        selected: boolean;
        airline?: {
          __typename?: 'FdrAirline';
          name: string;
          iata: string;
        } | null;
      }
    | {
        __typename: 'FdrSearchControlsDepartureFlightArrivalTimeOption';
        selected: boolean;
        id: string;
        visible: boolean;
        from?: any | null;
        to?: any | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
        selected: boolean;
        id: string;
        visible: boolean;
        from?: any | null;
        to?: any | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsFlightStopoverDurationOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
        availableRange?: {
          __typename?: 'FdrFlightStopoverDurationRange';
          minDuration?: number | null;
          maxDuration?: number | null;
        } | null;
        selectedRange?: {
          __typename?: 'FdrFlightStopoverDurationRange';
          minDuration?: number | null;
          maxDuration?: number | null;
        } | null;
      }
    | {
        __typename: 'FdrSearchControlsMaxPriceOption';
        selected: boolean;
        id: string;
        visible: boolean;
        maxPrice?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsMaxStopsOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
        value?: number | null;
      }
    | {
        __typename: 'FdrSearchControlsMinPriceOption';
        selected: boolean;
        id: string;
        visible: boolean;
        minPrice?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsReturnFlightArrivalTimeOption';
        selected: boolean;
        id: string;
        visible: boolean;
        from?: any | null;
        to?: any | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
        selected: boolean;
        id: string;
        visible: boolean;
        to?: any | null;
        from?: any | null;
        caption?: string | null;
      };
};

export type FdrFlightSingleSelectFilterFragment = {
  __typename: 'FdrFlightSearchControlsSingleSelectFilter';
  id: string;
  visible: boolean;
  name?: string | null;
  options: Array<
    | {
        __typename: 'FdrSearchControlsAirlineOption';
        visible: boolean;
        id: string;
        selected: boolean;
        airline?: {
          __typename?: 'FdrAirline';
          name: string;
          iata: string;
        } | null;
      }
    | {
        __typename: 'FdrSearchControlsDepartureFlightArrivalTimeOption';
        selected: boolean;
        id: string;
        visible: boolean;
        from?: any | null;
        to?: any | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
        selected: boolean;
        id: string;
        visible: boolean;
        from?: any | null;
        to?: any | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsFlightStopoverDurationOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
        availableRange?: {
          __typename?: 'FdrFlightStopoverDurationRange';
          minDuration?: number | null;
          maxDuration?: number | null;
        } | null;
        selectedRange?: {
          __typename?: 'FdrFlightStopoverDurationRange';
          minDuration?: number | null;
          maxDuration?: number | null;
        } | null;
      }
    | {
        __typename: 'FdrSearchControlsMaxPriceOption';
        selected: boolean;
        id: string;
        visible: boolean;
        maxPrice?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsMaxStopsOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
        value?: number | null;
      }
    | {
        __typename: 'FdrSearchControlsMinPriceOption';
        selected: boolean;
        id: string;
        visible: boolean;
        minPrice?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsReturnFlightArrivalTimeOption';
        selected: boolean;
        id: string;
        visible: boolean;
        from?: any | null;
        to?: any | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
        selected: boolean;
        id: string;
        visible: boolean;
        to?: any | null;
        from?: any | null;
        caption?: string | null;
      }
  >;
};

export type FdrFlightMultiSelectFilterFragment = {
  __typename: 'FdrFlightSearchControlsMultiSelectFilter';
  id: string;
  visible: boolean;
  name?: string | null;
  options: Array<
    | {
        __typename: 'FdrSearchControlsAirlineOption';
        visible: boolean;
        id: string;
        selected: boolean;
        airline?: {
          __typename?: 'FdrAirline';
          name: string;
          iata: string;
        } | null;
      }
    | {
        __typename: 'FdrSearchControlsDepartureFlightArrivalTimeOption';
        selected: boolean;
        id: string;
        visible: boolean;
        from?: any | null;
        to?: any | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
        selected: boolean;
        id: string;
        visible: boolean;
        from?: any | null;
        to?: any | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsFlightStopoverDurationOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
        availableRange?: {
          __typename?: 'FdrFlightStopoverDurationRange';
          minDuration?: number | null;
          maxDuration?: number | null;
        } | null;
        selectedRange?: {
          __typename?: 'FdrFlightStopoverDurationRange';
          minDuration?: number | null;
          maxDuration?: number | null;
        } | null;
      }
    | {
        __typename: 'FdrSearchControlsMaxPriceOption';
        selected: boolean;
        id: string;
        visible: boolean;
        maxPrice?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsMaxStopsOption';
        visible: boolean;
        id: string;
        selected: boolean;
        caption?: string | null;
        value?: number | null;
      }
    | {
        __typename: 'FdrSearchControlsMinPriceOption';
        selected: boolean;
        id: string;
        visible: boolean;
        minPrice?: number | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsReturnFlightArrivalTimeOption';
        selected: boolean;
        id: string;
        visible: boolean;
        from?: any | null;
        to?: any | null;
        caption?: string | null;
      }
    | {
        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
        selected: boolean;
        id: string;
        visible: boolean;
        to?: any | null;
        from?: any | null;
        caption?: string | null;
      }
  >;
};

export type FdrFilterOptionFdrSearchControlsAccommodationSizeOptionFragment = {
  __typename: 'FdrSearchControlsAccommodationSizeOption';
  visible: boolean;
  id: string;
  selected: boolean;
  caption?: string | null;
};

export type FdrFilterOptionFdrSearchControlsAirlineOptionFragment = {
  __typename: 'FdrSearchControlsAirlineOption';
  visible: boolean;
  id: string;
  selected: boolean;
  airline?: { __typename?: 'FdrAirline'; name: string; iata: string } | null;
};

export type FdrFilterOptionFdrSearchControlsDepartureAirportOptionFragment = {
  __typename: 'FdrSearchControlsDepartureAirportOption';
  visible: boolean;
  id: string;
  selected: boolean;
  caption?: string | null;
  airport?: {
    __typename?: 'FdrAirport';
    name: string;
    iata: string;
    codes?: Array<string> | null;
    isGroup: boolean;
  } | null;
};

export type FdrFilterOptionFdrSearchControlsDepartureFlightDepartureTimeOptionFragment =
  {
    __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
    visible: boolean;
    id: string;
    selected: boolean;
    from?: any | null;
    to?: any | null;
    caption?: string | null;
  };

export type FdrFilterOptionFdrSearchControlsFlightDurationOptionFragment = {
  __typename: 'FdrSearchControlsFlightDurationOption';
  visible: boolean;
  id: string;
  selected: boolean;
  maxDuration?: number | null;
  caption?: string | null;
};

export type FdrFilterOptionFdrSearchControlsFlightProviderOptionFragment = {
  __typename: 'FdrSearchControlsFlightProviderOption';
  visible: boolean;
  id: string;
  selected: boolean;
};

export type FdrFilterOptionFdrSearchControlsHpRatingOptionFragment = {
  __typename: 'FdrSearchControlsHpRatingOption';
  visible: boolean;
  id: string;
  selected: boolean;
  rating?: number | null;
  caption?: string | null;
};

export type FdrFilterOptionFdrSearchControlsMaxPriceOptionFragment = {
  __typename: 'FdrSearchControlsMaxPriceOption';
  visible: boolean;
  id: string;
  selected: boolean;
  maxPrice?: number | null;
  caption?: string | null;
};

export type FdrFilterOptionFdrSearchControlsMaxStopsOptionFragment = {
  __typename: 'FdrSearchControlsMaxStopsOption';
  visible: boolean;
  id: string;
  selected: boolean;
  caption?: string | null;
  value?: number | null;
};

export type FdrFilterOptionFdrSearchControlsMealTypeOptionFragment = {
  __typename: 'FdrSearchControlsMealTypeOption';
  visible: boolean;
  id: string;
  selected: boolean;
  caption?: string | null;
};

export type FdrFilterOptionFdrSearchControlsMinPriceOptionFragment = {
  __typename: 'FdrSearchControlsMinPriceOption';
  visible: boolean;
  id: string;
  selected: boolean;
  minPrice?: number | null;
  caption?: string | null;
};

export type FdrFilterOptionFdrSearchControlsProductCodeOptionFragment = {
  __typename: 'FdrSearchControlsProductCodeOption';
  visible: boolean;
  id: string;
  selected: boolean;
};

export type FdrFilterOptionFdrSearchControlsProductFeatureOptionFragment = {
  __typename: 'FdrSearchControlsProductFeatureOption';
  visible: boolean;
  id: string;
  selected: boolean;
  caption?: string | null;
};

export type FdrFilterOptionFdrSearchControlsProductProviderOptionFragment = {
  __typename: 'FdrSearchControlsProductProviderOption';
  visible: boolean;
  id: string;
  selected: boolean;
};

export type FdrFilterOptionFdrSearchControlsReturnFlightDepartureTimeOptionFragment =
  {
    __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
    visible: boolean;
    id: string;
    selected: boolean;
    to?: any | null;
    from?: any | null;
    caption?: string | null;
  };

export type FdrFilterOptionFdrSearchControlsRoomTypeOptionFragment = {
  __typename: 'FdrSearchControlsRoomTypeOption';
  visible: boolean;
  id: string;
  selected: boolean;
  caption?: string | null;
};

export type FdrFilterOptionFdrSearchControlsTaRatingOptionFragment = {
  __typename: 'FdrSearchControlsTaRatingOption';
  visible: boolean;
  id: string;
  selected: boolean;
  rating?: number | null;
  caption?: string | null;
};

export type FdrFilterOptionFragment =
  | FdrFilterOptionFdrSearchControlsAccommodationSizeOptionFragment
  | FdrFilterOptionFdrSearchControlsAirlineOptionFragment
  | FdrFilterOptionFdrSearchControlsDepartureAirportOptionFragment
  | FdrFilterOptionFdrSearchControlsDepartureFlightDepartureTimeOptionFragment
  | FdrFilterOptionFdrSearchControlsFlightDurationOptionFragment
  | FdrFilterOptionFdrSearchControlsFlightProviderOptionFragment
  | FdrFilterOptionFdrSearchControlsHpRatingOptionFragment
  | FdrFilterOptionFdrSearchControlsMaxPriceOptionFragment
  | FdrFilterOptionFdrSearchControlsMaxStopsOptionFragment
  | FdrFilterOptionFdrSearchControlsMealTypeOptionFragment
  | FdrFilterOptionFdrSearchControlsMinPriceOptionFragment
  | FdrFilterOptionFdrSearchControlsProductCodeOptionFragment
  | FdrFilterOptionFdrSearchControlsProductFeatureOptionFragment
  | FdrFilterOptionFdrSearchControlsProductProviderOptionFragment
  | FdrFilterOptionFdrSearchControlsReturnFlightDepartureTimeOptionFragment
  | FdrFilterOptionFdrSearchControlsRoomTypeOptionFragment
  | FdrFilterOptionFdrSearchControlsTaRatingOptionFragment;

export type FdrFlightFilterOptionFdrSearchControlsAirlineOptionFragment = {
  __typename: 'FdrSearchControlsAirlineOption';
  visible: boolean;
  id: string;
  selected: boolean;
  airline?: { __typename?: 'FdrAirline'; name: string; iata: string } | null;
};

export type FdrFlightFilterOptionFdrSearchControlsDepartureFlightArrivalTimeOptionFragment =
  {
    __typename: 'FdrSearchControlsDepartureFlightArrivalTimeOption';
    selected: boolean;
    id: string;
    visible: boolean;
    from?: any | null;
    to?: any | null;
    caption?: string | null;
  };

export type FdrFlightFilterOptionFdrSearchControlsDepartureFlightDepartureTimeOptionFragment =
  {
    __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
    selected: boolean;
    id: string;
    visible: boolean;
    from?: any | null;
    to?: any | null;
    caption?: string | null;
  };

export type FdrFlightFilterOptionFdrSearchControlsFlightStopoverDurationOptionFragment =
  {
    __typename: 'FdrSearchControlsFlightStopoverDurationOption';
    visible: boolean;
    id: string;
    selected: boolean;
    caption?: string | null;
    availableRange?: {
      __typename?: 'FdrFlightStopoverDurationRange';
      minDuration?: number | null;
      maxDuration?: number | null;
    } | null;
    selectedRange?: {
      __typename?: 'FdrFlightStopoverDurationRange';
      minDuration?: number | null;
      maxDuration?: number | null;
    } | null;
  };

export type FdrFlightFilterOptionFdrSearchControlsMaxPriceOptionFragment = {
  __typename: 'FdrSearchControlsMaxPriceOption';
  selected: boolean;
  id: string;
  visible: boolean;
  maxPrice?: number | null;
  caption?: string | null;
};

export type FdrFlightFilterOptionFdrSearchControlsMaxStopsOptionFragment = {
  __typename: 'FdrSearchControlsMaxStopsOption';
  visible: boolean;
  id: string;
  selected: boolean;
  caption?: string | null;
  value?: number | null;
};

export type FdrFlightFilterOptionFdrSearchControlsMinPriceOptionFragment = {
  __typename: 'FdrSearchControlsMinPriceOption';
  selected: boolean;
  id: string;
  visible: boolean;
  minPrice?: number | null;
  caption?: string | null;
};

export type FdrFlightFilterOptionFdrSearchControlsReturnFlightArrivalTimeOptionFragment =
  {
    __typename: 'FdrSearchControlsReturnFlightArrivalTimeOption';
    selected: boolean;
    id: string;
    visible: boolean;
    from?: any | null;
    to?: any | null;
    caption?: string | null;
  };

export type FdrFlightFilterOptionFdrSearchControlsReturnFlightDepartureTimeOptionFragment =
  {
    __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
    selected: boolean;
    id: string;
    visible: boolean;
    to?: any | null;
    from?: any | null;
    caption?: string | null;
  };

export type FdrFlightFilterOptionFragment =
  | FdrFlightFilterOptionFdrSearchControlsAirlineOptionFragment
  | FdrFlightFilterOptionFdrSearchControlsDepartureFlightArrivalTimeOptionFragment
  | FdrFlightFilterOptionFdrSearchControlsDepartureFlightDepartureTimeOptionFragment
  | FdrFlightFilterOptionFdrSearchControlsFlightStopoverDurationOptionFragment
  | FdrFlightFilterOptionFdrSearchControlsMaxPriceOptionFragment
  | FdrFlightFilterOptionFdrSearchControlsMaxStopsOptionFragment
  | FdrFlightFilterOptionFdrSearchControlsMinPriceOptionFragment
  | FdrFlightFilterOptionFdrSearchControlsReturnFlightArrivalTimeOptionFragment
  | FdrFlightFilterOptionFdrSearchControlsReturnFlightDepartureTimeOptionFragment;

export type FdrMaxStopsOptionFragment = {
  __typename: 'FdrSearchControlsMaxStopsOption';
  visible: boolean;
  id: string;
  selected: boolean;
  caption?: string | null;
  value?: number | null;
};

export type FdrStopoverDurationOptionFragment = {
  __typename: 'FdrSearchControlsFlightStopoverDurationOption';
  visible: boolean;
  id: string;
  selected: boolean;
  caption?: string | null;
  availableRange?: {
    __typename?: 'FdrFlightStopoverDurationRange';
    minDuration?: number | null;
    maxDuration?: number | null;
  } | null;
  selectedRange?: {
    __typename?: 'FdrFlightStopoverDurationRange';
    minDuration?: number | null;
    maxDuration?: number | null;
  } | null;
};

export type FdrHpRatingOptionFragment = {
  __typename: 'FdrSearchControlsHpRatingOption';
  selected: boolean;
  id: string;
  visible: boolean;
  rating?: number | null;
  caption?: string | null;
};

export type FdrTaRatingOptionFragment = {
  __typename: 'FdrSearchControlsTaRatingOption';
  rating?: number | null;
  selected: boolean;
  id: string;
  visible: boolean;
  caption?: string | null;
};

export type FdrProductFeatureOptionFragment = {
  __typename: 'FdrSearchControlsProductFeatureOption';
  id: string;
  visible: boolean;
  selected: boolean;
  caption?: string | null;
};

export type FdrRoomTypeOptionFragment = {
  __typename: 'FdrSearchControlsRoomTypeOption';
  id: string;
  visible: boolean;
  selected: boolean;
  caption?: string | null;
};

export type FdrMealTypeOptionFragment = {
  __typename: 'FdrSearchControlsMealTypeOption';
  id: string;
  visible: boolean;
  selected: boolean;
  caption?: string | null;
};

export type FdrDepartureAirportOptionFragment = {
  __typename: 'FdrSearchControlsDepartureAirportOption';
  id: string;
  visible: boolean;
  selected: boolean;
  caption?: string | null;
  airport?: {
    __typename?: 'FdrAirport';
    name: string;
    iata: string;
    codes?: Array<string> | null;
    isGroup: boolean;
  } | null;
};

export type FdrAccommodationSizOptionFragment = {
  __typename: 'FdrSearchControlsAccommodationSizeOption';
  id: string;
  visible: boolean;
  selected: boolean;
  caption?: string | null;
};

export type FdrReturnFlightDepartureTimeFragment = {
  __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
  selected: boolean;
  id: string;
  visible: boolean;
  to?: any | null;
  from?: any | null;
  caption?: string | null;
};

export type FdrAirlineOptionFragment = {
  __typename: 'FdrSearchControlsAirlineOption';
  visible: boolean;
  id: string;
  selected: boolean;
  airline?: { __typename?: 'FdrAirline'; name: string; iata: string } | null;
};

export type FdrFlightDurationOptionFragment = {
  __typename: 'FdrSearchControlsFlightDurationOption';
  visible: boolean;
  id: string;
  selected: boolean;
  maxDuration?: number | null;
  caption?: string | null;
};

export type FdrMinPriceOptionFragment = {
  __typename: 'FdrSearchControlsMinPriceOption';
  selected: boolean;
  id: string;
  visible: boolean;
  minPrice?: number | null;
  caption?: string | null;
};

export type FdrMaxPriceOptionFragment = {
  __typename: 'FdrSearchControlsMaxPriceOption';
  selected: boolean;
  id: string;
  visible: boolean;
  maxPrice?: number | null;
  caption?: string | null;
};

export type FdrDepartureFlightDepartureTimeFragment = {
  __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
  selected: boolean;
  id: string;
  visible: boolean;
  from?: any | null;
  to?: any | null;
  caption?: string | null;
};

export type FdrDepartureFlightArrivalTimeFragment = {
  __typename: 'FdrSearchControlsDepartureFlightArrivalTimeOption';
  selected: boolean;
  id: string;
  visible: boolean;
  from?: any | null;
  to?: any | null;
  caption?: string | null;
};

export type FdrReturnFlightArrivalTimeFragment = {
  __typename: 'FdrSearchControlsReturnFlightArrivalTimeOption';
  selected: boolean;
  id: string;
  visible: boolean;
  from?: any | null;
  to?: any | null;
  caption?: string | null;
};

export const FdrAccommodationSizOptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrAccommodationSizOption' },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'FdrSearchControlsAccommodationSizeOption',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'caption' },
            name: { kind: 'Name', value: 'name' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FdrMealTypeOptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrMealTypeOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchControlsMealTypeOption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'caption' },
            name: { kind: 'Name', value: 'name' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FdrRoomTypeOptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrRoomTypeOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchControlsRoomTypeOption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'caption' },
            name: { kind: 'Name', value: 'name' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FdrProductFeatureOptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrProductFeatureOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchControlsProductFeatureOption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'caption' },
            name: { kind: 'Name', value: 'name' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FdrHpRatingOptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrHpRatingOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchControlsHpRatingOption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'caption' },
            name: { kind: 'Name', value: 'name' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FdrTaRatingOptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrTaRatingOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchControlsTaRatingOption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'caption' },
            name: { kind: 'Name', value: 'name' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FdrMinPriceOptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrMinPriceOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchControlsMinPriceOption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'caption' },
            name: { kind: 'Name', value: 'name' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FdrMaxPriceOptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrMaxPriceOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchControlsMaxPriceOption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'caption' },
            name: { kind: 'Name', value: 'name' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FdrAirlineOptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrAirlineOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchControlsAirlineOption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'airline' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iata' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FdrDepartureAirportOptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrDepartureAirportOption' },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'FdrSearchControlsDepartureAirportOption',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'caption' },
            name: { kind: 'Name', value: 'name' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'airport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iata' } },
                { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isGroup' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FdrFlightDurationOptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrFlightDurationOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchControlsFlightDurationOption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'caption' },
            name: { kind: 'Name', value: 'name' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxDuration' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FdrReturnFlightDepartureTimeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrReturnFlightDepartureTime' },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'FdrSearchControlsReturnFlightDepartureTimeOption',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'caption' },
            name: { kind: 'Name', value: 'name' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FdrDepartureFlightDepartureTimeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrDepartureFlightDepartureTime' },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'FdrSearchControlsDepartureFlightDepartureTimeOption',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'caption' },
            name: { kind: 'Name', value: 'name' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FdrMaxStopsOptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrMaxStopsOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchControlsMaxStopsOption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'caption' },
            name: { kind: 'Name', value: 'name' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'value' },
            name: { kind: 'Name', value: 'maxStops' },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FdrFilterOptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrFilterOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchControlsFilterOption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrAccommodationSizOption' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrMealTypeOption' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrRoomTypeOption' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrProductFeatureOption' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrHpRatingOption' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrTaRatingOption' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrMinPriceOption' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrMaxPriceOption' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrAirlineOption' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrDepartureAirportOption' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrFlightDurationOption' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrReturnFlightDepartureTime' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrDepartureFlightDepartureTime' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrMaxStopsOption' },
          },
        ],
      },
    },
    ...FdrAccommodationSizOptionFragmentDoc.definitions,
    ...FdrMealTypeOptionFragmentDoc.definitions,
    ...FdrRoomTypeOptionFragmentDoc.definitions,
    ...FdrProductFeatureOptionFragmentDoc.definitions,
    ...FdrHpRatingOptionFragmentDoc.definitions,
    ...FdrTaRatingOptionFragmentDoc.definitions,
    ...FdrMinPriceOptionFragmentDoc.definitions,
    ...FdrMaxPriceOptionFragmentDoc.definitions,
    ...FdrAirlineOptionFragmentDoc.definitions,
    ...FdrDepartureAirportOptionFragmentDoc.definitions,
    ...FdrFlightDurationOptionFragmentDoc.definitions,
    ...FdrReturnFlightDepartureTimeFragmentDoc.definitions,
    ...FdrDepartureFlightDepartureTimeFragmentDoc.definitions,
    ...FdrMaxStopsOptionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrMultiSelectFilterFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrMultiSelectFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchControlsMultiSelectFilter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrFilterOption' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrFilterOptionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrOneOptionFilterFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrOneOptionFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchControlsOneOptionFilter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'option' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrFilterOption' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrFilterOptionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrSingleSelectFilterFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrSingleSelectFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchControlsSingleSelectFilter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrFilterOption' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrFilterOptionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrFiltersFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrFilters' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchControlsFilter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrMultiSelectFilter' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrOneOptionFilter' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrSingleSelectFilter' },
          },
        ],
      },
    },
    ...FdrMultiSelectFilterFragmentDoc.definitions,
    ...FdrOneOptionFilterFragmentDoc.definitions,
    ...FdrSingleSelectFilterFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrDepartureFlightArrivalTimeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrDepartureFlightArrivalTime' },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'FdrSearchControlsDepartureFlightArrivalTimeOption',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'caption' },
            name: { kind: 'Name', value: 'name' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FdrReturnFlightArrivalTimeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrReturnFlightArrivalTime' },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'FdrSearchControlsReturnFlightArrivalTimeOption',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'caption' },
            name: { kind: 'Name', value: 'name' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FdrStopoverDurationOptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrStopoverDurationOption' },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'FdrSearchControlsFlightStopoverDurationOption',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'caption' },
            name: { kind: 'Name', value: 'name' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availableRange' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'minDuration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxDuration' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectedRange' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'minDuration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxDuration' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FdrFlightFilterOptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrFlightFilterOption' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrFlightSearchControlsFilterOption' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrMinPriceOption' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrMaxPriceOption' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrMaxStopsOption' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrAirlineOption' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrDepartureFlightDepartureTime' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrReturnFlightDepartureTime' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrDepartureFlightArrivalTime' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrReturnFlightArrivalTime' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrStopoverDurationOption' },
          },
        ],
      },
    },
    ...FdrMinPriceOptionFragmentDoc.definitions,
    ...FdrMaxPriceOptionFragmentDoc.definitions,
    ...FdrMaxStopsOptionFragmentDoc.definitions,
    ...FdrAirlineOptionFragmentDoc.definitions,
    ...FdrDepartureFlightDepartureTimeFragmentDoc.definitions,
    ...FdrReturnFlightDepartureTimeFragmentDoc.definitions,
    ...FdrDepartureFlightArrivalTimeFragmentDoc.definitions,
    ...FdrReturnFlightArrivalTimeFragmentDoc.definitions,
    ...FdrStopoverDurationOptionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrFlightMultiSelectFilterFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrFlightMultiSelectFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'FdrFlightSearchControlsMultiSelectFilter',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrFlightFilterOption' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrFlightFilterOptionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrFlightOneOptionFilterFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrFlightOneOptionFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrFlightSearchControlsOneOptionFilter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'option' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrFlightFilterOption' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrFlightFilterOptionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrFlightSingleSelectFilterFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrFlightSingleSelectFilter' },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'FdrFlightSearchControlsSingleSelectFilter',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'options' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrFlightFilterOption' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrFlightFilterOptionFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrFlightFiltersFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrFlightFilters' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrFlightSearchControlsFilter' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrFlightMultiSelectFilter' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrFlightOneOptionFilter' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'fdrFlightSingleSelectFilter' },
          },
        ],
      },
    },
    ...FdrFlightMultiSelectFilterFragmentDoc.definitions,
    ...FdrFlightOneOptionFilterFragmentDoc.definitions,
    ...FdrFlightSingleSelectFilterFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
