import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
export type FdrTravelPeriodFragment = {
  __typename?: 'FdrSearchControlsTravelPeriod';
  defaultPeriod: Types.FdrTravelPeriodType;
  exactDepartureDate: any;
  exactReturnDate: any;
  flexibleDepartureDate: any;
  flexibleReturnDate: any;
  flexibleDurations: Array<number>;
};

export const FdrTravelPeriodFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrTravelPeriod' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchControlsTravelPeriod' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'defaultPeriod' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exactDepartureDate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'exactReturnDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flexibleDepartureDate' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flexibleReturnDate' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'flexibleDurations' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
